import 'bootstrap/js/dist/base-component';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import axios from 'axios';

window.$ = window.jQuery = require('jquery');

require('./components/Map')
require('./components/Menu')
require('./components/Schedule')
require('./components/Parallax')

const openEventModal = () => {
    const query = new URLSearchParams(window.location.search);

    if (query.get('open') === 'modal') {
        const event = query.get('event');

        const $btn = $(`[data-toggle="event-detail"][data-event="${event}"]`);

        $btn.trigger('click');

        const $modal = $($btn.data('target'));

        $modal.on('hidden.bs.modal', async function () {
            const href = window.location.href.replace(window.location.search, '');

            await window.history.replaceState({}, '', href);

            $(this).off('hidden.bs.modal');
        });
    }
};

const eventDetailHandler = async function (e) {
    e.preventDefault();

    const $btn = $(this);
    const $modal = $($btn.data('target'));
    const $loader = $('.loader', $modal);

    $modal.modal('show');

    const $body = $('.modal-body', $modal);

    const { data } = await axios.get(`/wp-json/schedule/event/${$btn.data('event')}`, {
        params: {
            bg: $btn.data('bg'),
        },
    });

    $body.html(data);

    setTimeout(function () {
        $loader.hide();
    }, 150);

    setTimeout(function () {
        $body.css('opacity', 1);
    }, 250);
};

const speakerDetailHandler = async function (e) {
    e.preventDefault();

    const $btn = $(this);
    const $modal = $($btn.data('target'));
    const $loader = $('.loader', $modal);

    $modal.modal('show');

    const $body = $('.modal-body', $modal);

    $body.html('<div class="loader"></div>');

    const { data } = await axios.get(`/wp-json/speaker/${$btn.data('speaker')}`);

    $body.html(data);

    setTimeout(function () {
        $loader.hide();
    }, 150);

    setTimeout(function () {
        $body.css('opacity', 1);
    }, 250);
};

$(function () {
    $(document).on('click', '[data-toggle="event-detail"]', eventDetailHandler);
    $(document).on('click', '[data-toggle="speaker-detail"]', speakerDetailHandler);

    openEventModal();

    if (document.location.pathname === '/') {
        $('.canvas--container').addClass('home-page');
    }

    document.addEventListener('shown.bs.modal', function (event) {
        $('html').addClass('overflow-hidden');
    });

    document.addEventListener('hidden.bs.modal', function (event) {
        $('html').removeClass('overflow-hidden');
    });
});
