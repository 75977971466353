$(document).ready(function () {
    const $html = $('html');
    const $body = $('body');
    const $header = $('[data-plugin="sticky"]');
    const $h_height = $('#top').innerHeight();
    const $button_close = $('[data-bs-toggle="collapse"].hamburger');

    // Hamburger
    if ($button_close.length > 0) {
        $button_close.on('click', function () {
            const $this = $(this);

            if (!$this.hasClass('is-active')) {
                $this.addClass('is-active');
                if ($(window).width() < 1200) {
                    $body.add($html).addClass('menu-open');
                }
            } else {
                $this.removeClass('is-active');
                $body.add($html).removeClass('menu-open');
            }
        });
    }

    // Sticky on mouse scroll
    if ($header.length > 0) {
        const position = $header.offset().top + $h_height;

        const updateHeader = () => {
            if ($(document).scrollTop() > position) {
                $header.add($html).addClass('sticky');
                $body.css('margin-top', $h_height + 64);
            } else {
                $header.add($html).removeClass('sticky');
                $body.css('margin-top', 0);
            }
        }

        $(window).scroll(updateHeader);
        updateHeader();
    }
});
