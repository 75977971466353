import * as basicScroll from 'basicscroll'

$(function () {
    const parallaxSections = document.querySelectorAll('.uq-parallax');

    parallaxSections.forEach(parallaxSection => {
        const from = parallaxSection.dataset.from || '0px';
        const to = parallaxSection.dataset.to || '100px';

        const instance = basicScroll.create({
            elem: parallaxSection,
            from: 'middle-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--ty': {
                    from: '-100px',
                    to: '0',
                }
            }
        });

        instance.start();
    });

    const parallaxAltSections = document.querySelectorAll('.uq-parallax-alt');

    parallaxAltSections.forEach(parallaxSection => {
        const from = parallaxSection.dataset.from || '0px';
        const to = parallaxSection.dataset.to || '100px';

        const instance = basicScroll.create({
            elem: parallaxSection,
            from: 'top-bottom',
            to: 'bottom-top',
            direct: true,
            props: {
                '--ty': {
                    from: '-100px',
                    to: '200px',
                }
            }
        });

        instance.start();
    });
});

