window.initMap = function () {
    $(function () {
        const els = document.getElementsByClassName('map-container');

        for (let map of els) {
            if (!map) {
                continue;
            }

            const lat = parseFloat(map.dataset.lat);
            const lng = parseFloat(map.dataset.lng);
            const zoom = parseFloat(map.dataset.zoom);
            const pointers = JSON.parse(map.dataset.pointers);
            const image = map.dataset.image;

            const mapInstance = new google.maps.Map(map, {
                center: { lat, lng },
                zoom,
            });

            if (pointers.length > 0) {
                for (const pointer of pointers) {
                    const pointerLat = parseFloat(pointer.lat);
                    const pointerLng = parseFloat(pointer.lng);

                    new google.maps.Marker({
                        position: { lat: pointerLat, lng: pointerLng },
                        map: mapInstance,
                        icon: image,
                    });
                }
            } else {
                new google.maps.Marker({
                    position: { lat, lng },
                    map: mapInstance,
                });
            }
        }
    });
}
